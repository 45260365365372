import styles from '../../styles/Profile.module.scss'
import Select from 'react-select'
import { choices, RARITY_LEVELS } from '../../lib/mapHero/mapHeroConstants'
import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import {
  HeroOptionsForm,
  HeroOptionFormObject,
} from '../../modules/hero/heroFilterFormInterface'
import { useDispatch, useSelector } from 'react-redux'
import heroFilterActions from '../../modules/hero/heroFilterActions'
import camelCaseToText from '../../lib/camelCaseToText'
import heroFilterSelectors from '../../modules/hero/heroFilterSelectors'

export default function HeroesFilter() {
  const dispatch = useDispatch()

  const initialData = useSelector(heroFilterSelectors.selectFilterData)

  const { account } = useWeb3React()
  const [selectedOptions, setSelectedOptions] = useState<HeroOptionsForm>()
  const [genderOptions, setGenderOptions] = useState<HeroOptionFormObject[]>()
  const [classOptions, setClassOptions] = useState<HeroOptionFormObject[]>()
  const [subClassOptions, setSubClassOptions] =
    useState<HeroOptionFormObject[]>()
  const [elementOptions, setElementOptions] = useState<HeroOptionFormObject[]>()
  const [rarityOptions, setRarityOptions] = useState<HeroOptionFormObject[]>()
  const generationOptions: HeroOptionFormObject[] = [
    { value: '0', label: 0 },
    { value: '1', label: 1 },
    { value: '2', label: 2 },
    { value: '3', label: 3 },
    { value: '4', label: 4 },
    { value: '5', label: 5 },
    { value: '6', label: 6 },
    { value: '7', label: 7 },
    { value: '8', label: 8 },
    { value: '9', label: 9 },
    { value: '10', label: 10 },
  ]

  useEffect(() => {
    camelCaseToText(choices.gender, setGenderOptions)
    camelCaseToText(choices.class, setClassOptions)
    camelCaseToText(choices.subClass, setSubClassOptions)
    camelCaseToText(choices.element, setElementOptions)
    camelCaseToText(RARITY_LEVELS, setRarityOptions)
  }, [account])

  const handleClick = (title: string, option) => {
    setSelectedOptions((prevState) => {
      return { ...prevState, [title]: option }
    })
  }

  useEffect(() => {
    setSelectedOptions(initialData)
  }, [])

  const handleSubmit = async () => {
    dispatch(heroFilterActions.doUpdateFilter(selectedOptions))
  }

  const handleReset = () => {
    setSelectedOptions({
      class: undefined,
      element: undefined,
      gender: undefined,
      generation: undefined,
      rarity: undefined,
      subClass: undefined,
    })
    dispatch(
      heroFilterActions.doUpdateFilter({
        class: undefined,
        element: undefined,
        gender: undefined,
        generation: undefined,
        rarity: undefined,
        subClass: undefined,
      }),
    )
  }

  return (
    <div className={styles.filter}>
      <Select
        className={`custom-select h40 ${styles.filterSelect}`}
        classNamePrefix="custom-select"
        options={genderOptions}
        onChange={(option) => handleClick('gender', option)}
        value={selectedOptions?.gender || 'Gender...'}
        placeholder={'Gender...'}
      />
      <Select
        className={`custom-select h40 ${styles.filterSelect}`}
        classNamePrefix="custom-select"
        options={classOptions}
        onChange={(option) => handleClick('class', option)}
        value={selectedOptions?.class || 'Class...'}
        placeholder={'Class...'}
      />
      <Select
        className={`custom-select h40 ${styles.filterSelect}`}
        classNamePrefix="custom-select"
        options={subClassOptions}
        onChange={(option) => handleClick('subClass', option)}
        value={selectedOptions?.subClass || 'Sub class...'}
        placeholder={'Sub class...'}
      />
      <Select
        className={`custom-select h40 ${styles.filterSelect}`}
        classNamePrefix="custom-select"
        options={elementOptions}
        onChange={(option) => handleClick('element', option)}
        value={selectedOptions?.element || 'Element'}
        placeholder={'Element'}
      />
      <Select
        className={`custom-select h40 ${styles.filterSelect}`}
        classNamePrefix="custom-select"
        options={rarityOptions}
        onChange={(option) => handleClick('rarity', option)}
        value={selectedOptions?.rarity || 'Rarity...'}
        placeholder={'Rarity...'}
      />
      <Select
        className={`custom-select h40 ${styles.filterSelect}`}
        classNamePrefix="custom-select"
        options={generationOptions}
        onChange={(option) => handleClick('generation', option)}
        value={selectedOptions?.generation || 'Generation...'}
        placeholder={'Generation...'}
      />
      <button
        onClick={() => {
          handleSubmit().then(() => {})
        }}
        className={`btn btn--default btn--green ${styles.filterButton}`}
      >
        Apply
      </button>
      <button
        onClick={() => {
          handleReset()
        }}
        className={`btn btn--default btn--blue ${styles.filterButton}`}
      >
        Reset
      </button>
    </div>
  )
}
