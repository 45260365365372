import { useWeb3React } from '@web3-react/core'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useHeroes from '../../hooks/useHeroes'
import heroFilterActions from '../../modules/hero/heroFilterActions'
import heroFilterSelectors from '../../modules/hero/heroFilterSelectors'
import HeroService from '../../modules/hero/heroService'
import liveGamesSelectors from '../../modules/liveGames/liveGamesSelectors'
import styles from '../../styles/HeroSelection.module.scss'
import HeroCard from '../heroCard/HeroCard'
import Pagination from '../pagination'
import Spinner from '../spinner'
import { filterHeroes } from './filterHeroes'
import HeroesFilter from './HeroesFilter'

interface HeroesSelectionListProps {
  onHeroSelect?: (hero: any) => void
  duelToJoin?: any
  cooldownLoading?: boolean
}

export function HeroesSelectionList(props: HeroesSelectionListProps) {
  const { onHeroSelect, duelToJoin, cooldownLoading } = props

  const { account } = useWeb3React()
  const router = useRouter()
  const dispatch = useDispatch()
  const { status, heroes, doRefresh } = useHeroes()
  const [flippedCards, setFlippedCards] = useState<any>({})
  const filteredHeroes = useSelector(heroFilterSelectors.selectFilteredHeroes)
  const heroOptionsForm = useSelector(heroFilterSelectors.selectFilterData)
  const totalCount = useSelector(heroFilterSelectors.selectTotal)
  const renderedPage = useSelector(heroFilterSelectors.selectRenderedPage)
  const itemsPerPage = useSelector(heroFilterSelectors.selectItemsPerPage)
  const heroesIdsInUse = useSelector(liveGamesSelectors.selectHeroesIdsInUse)

  function onPageChange(page) {
    dispatch(heroFilterActions.doChangePage(page))
  }

  useEffect(() => {
    dispatch(
      heroFilterActions.doFilteredHeroes(filterHeroes(heroOptionsForm, heroes)),
    )
  }, [heroOptionsForm, status])

  const filteredHeroesNotInUse = useMemo(() => {
    if (heroesIdsInUse) {
      return filteredHeroes.filter(
        (hero) => !heroesIdsInUse.some((id) => Number(id) === Number(hero.id)),
      )
    }

    return filteredHeroes
  }, [filteredHeroes, duelToJoin])

  function doClearCacheAndRefresh() {
    HeroService.clearCache()
    doRefresh(account)
  }

  return (
    <>
      {!router.route.includes('/profile') && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 16,
            marginBottom: 16,
          }}
        >
          <h1 className={styles.title}>Select Your Hero</h1>
          <button
            onClick={doClearCacheAndRefresh}
            style={{ paddingLeft: 8, paddingRight: 8 }}
            className={`btn btn--default btn--dark`}
          >
            Refresh
          </button>
        </div>
      )}
      <HeroesFilter />
      <div>
        {status === 'error' && (
          <div className={`heading ${styles.title}`}>
            Network Error. Please try again later.
          </div>
        )}
        {status === 'loading' && <Spinner />}
        {(status === 'empty' ||
          (status === 'idle' && !filteredHeroes.length)) && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className={`${styles.empty}`}>No available heroes found</div>
              <div
                style={{ marginTop: 16, maxWidth: 420, lineHeight: 1.5 }}
                className={`${styles.duelLevelRange}`}
              >
                Heroes in a duel, questing, or on sale will not show up here.
              </div>
            </div>
          </>
        )}
      </div>
      {status === 'idle' && filteredHeroesNotInUse.length !== 0 && (
        <>
          <div className={styles.tokens}>
            {filteredHeroesNotInUse.map((hero) => (
              <div key={hero.id}>
                <div className={styles.token}>
                  <div
                    onClick={() => {
                      setFlippedCards({
                        ...flippedCards,
                        [hero.id]: !flippedCards[hero.id],
                      })
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <HeroCard
                      hero={hero}
                      isAnimated={true}
                      size="large"
                      isFlipped={flippedCards[hero.id]}
                    />
                  </div>
                </div>
                {onHeroSelect && (
                  <button
                    onClick={() => {
                      onHeroSelect(hero)
                    }}
                    disabled={cooldownLoading}
                    className={`btn btn--small btn--blue ${styles.joinButton}`}
                  >
                    Select Hero
                  </button>
                )}
              </div>
            ))}
          </div>
        </>
      )}
      <Pagination
        currentPage={renderedPage}
        itemsPerPage={itemsPerPage}
        totalCount={totalCount}
        onPageChange={onPageChange}
      />
    </>
  )
}
