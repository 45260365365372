import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { useDispatch } from 'react-redux'
import formatGold from '../lib/formatGold'
import heroClassLabel from '../lib/heroClassLabel'
import isAddressEqual from '../lib/isAddressEqual'
import { RARITY_COLORS } from '../lib/mapHero/mapHeroConstants'
import userModalActions from '../modules/users/userModalActions'
import styles from '../styles/Games.module.scss'
import { shortenHex } from '../util'
import DuelPostGameOrShareButton from './duel/DuelPostGameOrShareButton'
import { HeroViewModal } from './heroes/heroViewModal'
import ProfileAvatar from './ProfileAvatar'

interface GameRowProps {
  completeGame: any
}

const GameRow = (props: GameRowProps) => {
  const dispatch = useDispatch()
  const { completeGame } = props

  const [viewingHero, setViewingHero] = React.useState(null)

  const isPlayer1Winner = isAddressEqual(
    completeGame?.player1?.address,
    completeGame?.winnerAddress,
  )

  const isPlayer2Winner = !isPlayer1Winner

  const winner = isPlayer1Winner ? completeGame?.player1 : completeGame?.player2
  const winnerHero = isPlayer1Winner
    ? completeGame?.player1Hero
    : completeGame?.player2Hero

  const loser = isPlayer2Winner ? completeGame?.player1 : completeGame?.player2
  const loserHero = isPlayer2Winner
    ? completeGame?.player1Hero
    : completeGame?.player2Hero

  return (
    <tr key={completeGame.id} className={`${styles.row} ${styles.bodyRow}`}>
      <td className={`${styles.bold}`} align="center">
        {Boolean(viewingHero) && (
          <HeroViewModal
            isOpen={Boolean(viewingHero)}
            onClose={() => setViewingHero(null)}
            hero={viewingHero}
          />
        )}

        <div
          data-tip={`<span>${completeGame.gameType}</span>`}
          data-html={true}
        >
          <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.54545 0H1.63636C0.733909 0 0 0.748333 0 1.66667V7.5C0 8.41833 0.733909 9.16667 1.63636 9.16667H6.54545C7.44791 9.16667 8.18182 8.41833 8.18182 7.5V1.66667C8.18182 0.748333 7.44791 0 6.54545 0Z"
              fill="#818196"
            />
            <path
              d="M16.3636 0H11.4545C10.5521 0 9.81818 0.748333 9.81818 1.66667V7.5C9.81818 8.41833 10.5521 9.16667 11.4545 9.16667H16.3636C17.2661 9.16667 18 8.41833 18 7.5V1.66667C18 0.748333 17.2661 0 16.3636 0Z"
              fill="#818196"
            />
            <path
              d="M6.54545 10.8333H1.63636C0.733909 10.8333 0 11.5817 0 12.5V18.3333C0 19.2525 0.733909 20 1.63636 20H6.54545C7.44791 20 8.18182 19.2525 8.18182 18.3333V12.5C8.18182 11.5817 7.44791 10.8333 6.54545 10.8333Z"
              fill="#818196"
            />
            <path
              d="M16.3636 10.8333H11.4545C10.5521 10.8333 9.81818 11.5817 9.81818 12.5V18.3333C9.81818 19.2525 10.5521 20 11.4545 20H16.3636C17.2661 20 18 19.2525 18 18.3333V12.5C18 11.5817 17.2661 10.8333 16.3636 10.8333Z"
              fill="#818196"
            />
          </svg>
        </div>
      </td>
      <td className={`${styles.bold}`} align="right">
        {completeGame.id}
      </td>
      <td className={`${styles.time}`} align="center">
        <div
          data-tip={`<span>${moment(completeGame.completed).format(
            'DD.MM.YYYY HH:mm:ss',
          )}</span>`}
          data-html={true}
        >
          {moment(completeGame.completed).fromNow()}
        </div>
      </td>
      <td>
        <div
          className={`${styles.players} ${
            completeGame.isDraw ? styles.playersdraw : ''
          }`}
        >
          <div className={styles.player}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                dispatch(userModalActions.doOpenModal(winner.address))
              }}
              className={styles.name}
            >
              {winner.name || shortenHex(winner.address)}
            </div>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                dispatch(userModalActions.doOpenModal(winner.address))
              }}
            >
              <ProfileAvatar
                className={styles.avatar}
                profile={winner}
                size={'small'}
              />
            </span>
            <div
              style={{ marginLeft: 12, cursor: 'pointer' }}
              className={styles.token}
              data-tip={`<div style="font-weight: 500"><div>${
                winnerHero?.name
              }</div><div style="margin-top: 8px; color: ${
                RARITY_COLORS[winnerHero?.rarity]
              }">${heroClassLabel(winnerHero?.statGenes?.class)}<div></div>`}
              data-html={true}
              onClick={() => setViewingHero(winnerHero)}
            >
              <div
                className={styles.avatar}
                style={{
                  padding: 4,
                  borderColor: RARITY_COLORS[winnerHero?.rarity],
                  background: '#64748b',
                }}
              >
                <Image
                  src={heroIcon(winnerHero?.statGenes?.class)}
                  width={30}
                  height={30}
                />
              </div>
            </div>
          </div>
          <div className={styles.player}>
            <div
              onClick={() => setViewingHero(loserHero)}
              style={{ cursor: 'pointer', marginRight: 12 }}
              className={styles.token}
              data-tip={`<div style="font-weight: 500"><div>${
                loserHero?.name
              }</div><div style="margin-top: 8px; color: ${
                RARITY_COLORS[loserHero?.rarity]
              }">${heroClassLabel(loserHero?.statGenes?.class)}<div></div>`}
              data-html={true}
            >
              <div
                className={styles.avatar}
                style={{
                  padding: 4,
                  borderColor: RARITY_COLORS[loserHero?.rarity],
                  background: '#64748b',
                }}
              >
                <Image
                  src={heroIcon(loserHero?.statGenes?.class)}
                  width={30}
                  height={30}
                />
              </div>
            </div>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                dispatch(userModalActions.doOpenModal(loser?.address))
              }}
            >
              <ProfileAvatar
                className={styles.avatar}
                profile={loser}
                size={'small'}
              />
            </span>
            <div
              className={styles.name}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                dispatch(userModalActions.doOpenModal(loser?.address))
              }}
            >
              {loser?.name || shortenHex(loser?.address)}
            </div>
          </div>
        </div>

        <div className={`${styles.playersSmall}`}>
          <div className={styles.playersSmallPlayer}>
            <ProfileAvatar
              className={styles.avatar}
              profile={completeGame?.player1}
              size={'small'}
            />
            <div className={styles.name}>
              {completeGame?.player1?.name ||
                shortenHex(completeGame?.player1?.address)}
            </div>
          </div>
          <div className={styles.playersSmallPlayer}>
            <ProfileAvatar
              className={styles.avatar}
              profile={completeGame?.player2}
              size={'small'}
            />
            <div className={styles.name}>
              {completeGame?.player2?.name ||
                shortenHex(completeGame?.player2?.address)}
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className={`${styles.bet}`}>
          <Image alt="Coin" src={'/gold_coin.png'} width={18} height={18} />
          {formatGold(completeGame.bet)}
        </div>
      </td>
      <td>
        <div className={`${styles.btns}`}>
          <DuelPostGameOrShareButton pairId={completeGame.pairId} />
          <Link href={`/duel/${completeGame.pairId}`}>
            <a className="btn btn--default btn--dark">
              <span className={styles.btnText}>Show Game</span>
              <span data-tip="Show Game" className={styles.btnIcon}>
                <FontAwesomeIcon icon={faEye} />
              </span>
            </a>
          </Link>
        </div>
      </td>
    </tr>
  )
}

export default React.memo(GameRow)

function heroIcon(heroClass: string) {
  switch (heroClass) {
    case 'archer': {
      return `/svgs/male/weapons/archer-weapon.svg`
    }
    case 'knight': {
      return `/svgs/male/weapons/knight-weapon.svg`
    }
    case 'monk': {
      return `/svgs/male/weapons/monk-weapon.svg`
    }
    case 'pirate': {
      return `/svgs/male/weapons/pirate-weapon.svg`
    }
    case 'priest': {
      return `/svgs/male/weapons/priest-weapon.svg`
    }
    case 'thief': {
      return `/svgs/male/weapons/thief-weapon.svg`
    }
    case 'warrior': {
      return `/svgs/male/weapons/warrior-weapon.svg`
    }
    case 'wizard': {
      return `/svgs/male/weapons/wizard-weapon.svg`
    }
    case 'paladin': {
      return `/svgs/male/weapons/paladin-weapon.svg`
    }
    case 'darkKnight': {
      return `/svgs/male/weapons/darkKnight-weapon.svg`
    }
    case 'summoner': {
      return `/svgs/male/weapons/summoner-weapon.svg`
    }
    case 'ninja': {
      return `/svgs/male/weapons/ninja-weapon.svg`
    }
    case 'dragoon': {
      return `/svgs/male/weapons/dragoon-weapon.svg`
    }
    case 'sage': {
      return `/svgs/male/weapons/sage-weapon.svg`
    }
    case 'dreadKnight': {
      return `/svgs/male/weapons/dreadknight-weapon.svg`
    }
    default: {
      return null
    }
  }
}
