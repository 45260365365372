import moment from 'moment'

export const calculateRemainingStamina = (hero) => {
  // secondsPerStaminaPoint is 5 if chainId === ChainId.HARMONY_TESTNET
  const secondsPerStaminaPoint = 1200
  const currentTime = moment(new Date())
  const staminaFullAt =
    hero.staminaFullAt && moment(new Date(hero.staminaFullAt))

  if (!staminaFullAt || staminaFullAt <= currentTime) {
    return hero.stats.stamina
  }

  const diffInSeconds = staminaFullAt.diff(currentTime, 'seconds')
  const finalDiff = diffInSeconds

  if (finalDiff) {
    return hero.stats.stamina - Math.ceil(finalDiff / secondsPerStaminaPoint)
  } else {
    return hero.stats.stamina
  }
}

export const updatedStaminaCooldown = (hero, staminaSubtracted) => {
  // secondsPerStaminaPoint is 5 if chainId === ChainId.HARMONY_TESTNET
  const secondsPerStaminaPoint = 1200
  const staminaFullAt =
    hero.staminaFullAt.ts === 0 || moment(hero.staminaFullAt).isBefore(moment())
      ? moment(new Date())
      : moment(new Date(hero.staminaFullAt))
  return staminaFullAt.add(
    secondsPerStaminaPoint * staminaSubtracted,
    'seconds',
  )
}
