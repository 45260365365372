import { useWeb3React } from '@web3-react/core'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import completedGamesActions from '../modules/completedGames/completedGamesActions'
import completedGamesSelectors from '../modules/completedGames/completedGamesSelectors'
import styles from '../styles/Games.module.scss'
import GameRow from './gameRow'
import Pagination from './pagination'
import Spinner from './spinner'

// import sprite from '../img/sprite.svg';

export function GamesWithHeading() {
  const dispatch = useDispatch()
  const completedGames = useSelector(completedGamesSelectors.selectData)
  const state = useSelector(completedGamesSelectors.selectState)
  const currentPage = useSelector(completedGamesSelectors.selectRenderedPage)
  const itemsPerPage = useSelector(completedGamesSelectors.selectItemsPerPage)
  const totalCount = useSelector(completedGamesSelectors.selectTotal)
  const router = useRouter()
  const { account } = useWeb3React()

  useEffect(() => {
    dispatch(
      completedGamesActions.doFetch(
        account,
        router,
        currentPage,
        itemsPerPage,
        null,
      ),
    )
  }, [])

  useEffect(() => {
    if (String(currentPage) === '0') {
      let pooling = setInterval(() => {
        ;(async () => {
          dispatch(
            completedGamesActions.doFetch(
              account,
              router,
              currentPage,
              itemsPerPage,
              null,
              true,
            ),
          )
        })()
      }, 30 * 1000)

      return () => {
        if (pooling) {
          clearInterval(pooling)
        }
      }
    }
  }, [currentPage, itemsPerPage])

  function onPageChange(page) {
    dispatch(completedGamesActions.doChangePage(account, router, page))
  }

  return (
    <>
      {(state === 'loading' ||
        (state === 'idle' && Boolean(completedGames.length))) && (
        <div className={`heading ${styles.secondTitle}`}>completed games</div>
      )}

      {state === 'loading' && <Spinner />}

      <div className={`${styles.games}`}>
        {state === 'idle' && Boolean(completedGames.length) && (
          <>
            <table className={styles.table}>
              <thead>
                <tr className={`${styles.row}`}>
                  <th className={`${styles.headItem}`} align="center">
                    game
                  </th>
                  <th className={`${styles.headItem}`} align="right">
                    ID
                  </th>
                  <th className={`${styles.headItem}`} align="center">
                    completed
                  </th>
                  <th
                    className={`${styles.headItem}`}
                    style={{ textAlign: 'center' }}
                  >
                    Players
                  </th>
                  <th className={`${styles.headItem}`}>bet</th>
                  <th className={`${styles.headItem}`}></th>
                </tr>
              </thead>
              <tbody>
                {completedGames.map((completeGame) => (
                  <GameRow key={completeGame.id} completeGame={completeGame} />
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalCount={totalCount}
              onPageChange={onPageChange}
            />
          </>
        )}
      </div>

      {/* @ts-ignore */}
      <ReactTooltip className="dfkTooltip" effect="solid" />
    </>
  )
}

export function ProfileGameHistory(props: any) {
  const dispatch = useDispatch()
  const { account } = props
  const router = useRouter()
  const completedGames = useSelector(completedGamesSelectors.selectData)
  const state = useSelector(completedGamesSelectors.selectState)
  const currentPage = useSelector(completedGamesSelectors.selectRenderedPage)
  const itemsPerPage = useSelector(completedGamesSelectors.selectItemsPerPage)
  const totalCount = useSelector(completedGamesSelectors.selectTotal)

  useEffect(() => {
    dispatch(
      completedGamesActions.doFetch(
        account,
        router,
        currentPage,
        itemsPerPage,
        account,
      ),
    )
  }, [account])

  useEffect(() => {
    if (String(currentPage) === '0') {
      let pooling = setInterval(() => {
        ;(async () => {
          dispatch(
            completedGamesActions.doFetch(
              account,
              router,
              currentPage,
              itemsPerPage,
              account,
              true,
            ),
          )
        })()
      }, 30 * 1000)

      return () => {
        if (pooling) {
          clearInterval(pooling)
        }
      }
    }
  }, [currentPage, itemsPerPage, account])

  function onPageChange(page) {
    dispatch(completedGamesActions.doChangePage(account, router, page))
  }

  return (
    <div className={`${styles.games}`}>
      {state === 'loading' && <Spinner />}
      {state === 'idle' && (
        <>
          <table className={styles.table}>
            <tbody>
              {completedGames.map((completeGame) => (
                <GameRow key={completeGame.id} completeGame={completeGame} />
              ))}
            </tbody>
          </table>
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalCount={totalCount}
            onPageChange={onPageChange}
          />
        </>
      )}
      {/* @ts-ignore */}
      <ReactTooltip className="dfkTooltip" effect="solid" />
    </div>
  )
}
