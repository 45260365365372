import { capitalize, toLower } from 'lodash'

export default function heroClassLabel(heroClass) {
  if (toLower(heroClass) === 'darkknight') {
    return 'Dark Knight'
  }

  if (toLower(heroClass) === 'dreadknight') {
    return 'Dread Knight'
  }

  return capitalize(heroClass)
}
