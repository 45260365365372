import { createSelector } from 'reselect'


const selectRaw = (state) => state.heroFilter

const selectFilterData = createSelector([selectRaw], (raw) => raw.filterData || {
  class: null,
  element: null,
  gender: null,
  generation: null,
  rarity: null,
  subClass: null,
})

const selectTotalHeroes = createSelector([selectRaw], (raw) => raw.filteredHeroes)
const selectRenderedPage = createSelector([selectRaw], (raw) => raw.renderedPage)
const selectItemsPerPage = createSelector([selectRaw], (raw) => raw.itemsPerPage)
const selectTotal = createSelector([selectRaw], (raw) => raw.count)
const selectFilteredHeroes = createSelector([selectTotalHeroes, selectRenderedPage, selectItemsPerPage], (raw, currentPage, limit) => {
  return raw.slice(currentPage * limit, currentPage * limit + limit)
})

const heroFilterSelectors = {
  selectTotalHeroes,
  selectRenderedPage,
  selectItemsPerPage,
  selectTotal,
  selectFilteredHeroes,
  selectFilterData,
  selectRaw,
}

export default heroFilterSelectors