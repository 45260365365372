import { useState } from 'react'
import Modal from 'react-modal'
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll'
import Messages from '../../lib/messages'
import { Web3Errors } from '../../modules/error/Web3Errors'
import HeroService from '../../modules/hero/heroService'
import styles from '../../styles/Modals.module.scss'
import HeroCard from '../heroCard/HeroCard'
import { HeroesSelectionList } from './HeroesSelectionList'

interface HeroViewModalProps {
  hero
  onClose: () => void
  isOpen?: boolean
}

export function HeroViewModal(props: HeroViewModalProps) {
  const [isFlipped, setIsFlipped] = useState(false)
  const { onClose, isOpen, hero } = props

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel={'View Hero'}
        className={`dfkModal ${styles.heroViewModal}`}
        closeTimeoutMS={200}
      >
        <div
          onClick={() => setIsFlipped(!isFlipped)}
          style={{ cursor: 'pointer' }}
        >
          <HeroCard
            hero={hero}
            isAnimated={true}
            size="large"
            isFlipped={isFlipped}
          />
        </div>
        <button
          className="btn dfkModal__close"
          aria-label="Close modal"
          onClick={onClose}
        />
      </Modal>
    </>
  )
}
