import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Web3 from 'web3'
import BattleContractAbi from '../../contracts/BattleContractAbi'
import authActions from '../../modules/auth/authActions'
import balanceActions from '../../modules/balance/balanceActions'
import { ConfigStore } from '../../modules/config/configStore'
import { Web3Errors } from '../../modules/error/Web3Errors'
import persistentToastActions from '../../modules/persistentToast/persistentToastActions'
import LiveGamesService from '../../modules/liveGames/liveGamesService'
import Messages from '../../lib/messages'
import CopyToClipboard from 'react-copy-to-clipboard'
import styles from '../../styles/Games.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClose,
  faCopy,
  faFlagCheckered,
} from '@fortawesome/free-solid-svg-icons'
interface DuelPostGameOrShareButtonProps {
  pairId: string
}

export default function DuelPostGameOrShareButton(
  props: DuelPostGameOrShareButtonProps,
) {
  const { pairId } = props
  const { account } = useWeb3React()
  const dispatch = useDispatch()

  const [state, setState] = useState('verfiying')

  useEffect(() => {
    doCheckHasDuelPostGame()
  }, [account])

  useEffect(() => {
    if (state === 'idle') {
      let interval = setInterval(() => {
        doCheckHasDuelPostGame()
      }, 2000)

      return () => {
        interval && clearInterval(interval)
      }
    }
  }, [state])

  const doCheckHasDuelPostGame = async () => {
    if (!account) {
      setState('doesnt_apply')
      return
    }
    try {
      // @ts-ignore
      const web3Instance = new Web3(window.ethereum)

      const battleContract = new web3Instance.eth.Contract(
        BattleContractAbi,
        ConfigStore.get().BATTLE_CONTRACT_ADDRESS,
      )

      if (await LiveGamesService.isPostDuel(account, pairId, battleContract)) {
        setState('idle')
      } else {
        setState('doesnt_apply')
      }
    } catch (error) {
      console.error(error)
      setState('doesnt_apply')
    }
  }

  const callContractToDuelPostGame = async () => {
    if (!account) {
      dispatch(authActions.doOpenModal())
      return
    }

    try {
      setState('loading')
      dispatch(
        persistentToastActions.doAddToast(
          'DuelPostGameButton',
          'Waiting for end game confirmation...',
        ),
      )
      // @ts-ignore
      const web3Instance = new Web3(window.ethereum)

      const battleContract = new web3Instance.eth.Contract(
        BattleContractAbi,
        ConfigStore.get().BATTLE_CONTRACT_ADDRESS,
      )

      await battleContract.methods
        .post_fight_updates(pairId)
        .send({ from: account })

      dispatch(balanceActions.doFetch(account, true))

      setState('success')
    } catch (error) {
      Web3Errors.handle(error)
      setState('idle')
    } finally {
      dispatch(persistentToastActions.doRemoveToast('DuelPostGameButton'))
    }
  }

  const shareButton = (
    <CopyToClipboard
      text={`${window.location.origin}/duel/${pairId}`}
      onCopy={() => Messages.success('Duel link copied to clipboard.')}
    >
      <button className="btn btn--default btn--dark">
        <span className={styles.btnText}>Share</span>
        <span data-tip="Share" className={styles.btnIcon}>
          <FontAwesomeIcon icon={faCopy} />
        </span>
      </button>
    </CopyToClipboard>
  )

  if (state === 'verfiying') {
    return shareButton
  }

  if (state === 'doesnt_apply') {
    return shareButton
  }

  if (state === 'success') {
    return shareButton
  }

  return (
    <button
      disabled={state === 'loading'}
      onClick={callContractToDuelPostGame}
      className="btn btn--default btn--green"
    >
      <span className={styles.btnText}>End Game</span>
      <span data-tip="Share" className={styles.btnIcon}>
        <FontAwesomeIcon icon={faFlagCheckered} />
      </span>
    </button>
  )
}
