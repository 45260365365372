import React from 'react'

import styled from 'styled-components'
const darkIcon = '/svgs/icons/element-dark.png'
const earthIcon = '/svgs/icons/element-earth.png'
const fireIcon = '/svgs/icons/element-fire.png'
const iceIcon = '/svgs/icons/element-ice.png'
const lightIcon = '/svgs/icons/element-light.png'
const lightningIcon = '/svgs/icons/element-lightning.png'
const waterIcon = '/svgs/icons/element-water.png'
const windIcon = '/svgs/icons/element-wind.png'
const arcticIcon = '/svgs/icons/icon-arctic.png'
const cityIcon = '/svgs/icons/icon-city.png'
const desertIcon = '/svgs/icons/icon-desert.png'
const femaleIcon = '/svgs/icons/icon-female.png'
const forestIcon = '/svgs/icons/icon-forest.png'
const healthIcon = '/svgs/icons/icon-health.png'
const islandIcon = '/svgs/icons/icon-island.png'
const maleIcon = '/svgs/icons/icon-male.png'
const manaIcon = '/svgs/icons/icon-mana.png'
const mountainIcon = '/svgs/icons/icon-mountains.png'
const plainsIcon = '/svgs/icons/icon-plains.png'
const swampIcon = '/svgs/icons/icon-swamp.png'
const commonIcon = '/svgs/icons/rarity-common.png'
const legendaryIcon = '/svgs/icons/rarity-legendary.png'
const mythicIcon = '/svgs/icons/rarity-mythic.png'
const rareIcon = '/svgs/icons/rarity-rare.png'
const uncommonIcon = '/svgs/icons/rarity-uncommon.png'
import Hero from '../HeroSprite'
import HeroInfo from '../HeroInfo'
import HeroStatsSkills from '../HeroStatsSkills'
import styles from '../styles.module.css'

// Styled Components
const CardContainer = styled.div.attrs((props) => ({
  className: 'cardContainer',
}))`
  width: 300px;
  height: 430px;
  margin: 0;

  --scale: ${(props) => props.scale};
  transform: scale(var(--scale));
  transform-origin: top left;
  margin-bottom: calc((var(--scale) - 1) * 100%);
`

/* exported component */
const HeroCard = (props) => {
  let { isFlipped, hero, isAnimated, size } = props

  size = size || 'large'

  const wrapperStyle = {
    large: {
      width: '210px',
      height: '301px',
    },
    medium: { width: '120px', height: '172px' },
    small: { width: '33px', height: '46px' },
  }

  const scale = {
    large: 0.7,
    medium: 0.4,
    small: 0.1,
  }

  return (
    <>
      {hero && (
        <div style={wrapperStyle[size]}>
          <CardContainer key={hero.id} scale={scale[size]}>
            <div
              className={`
          ${styles.heroCard}
          ${isAnimated && styles.animate}
          ${hero.shiny ? styles.shiny : ''}
          ${hero.shiny ? styles[`shiny${hero.shinyStyle}`] : ''}
          ${styles[`${hero.element}`]}
          ${styles[`${hero.rarity}`]}
          ${isFlipped ? styles.flipped : ''}
          `}
            >
              <div className={styles.heroCardFront}>
                <div className={styles.heroID}>#{hero.id}</div>
                <div className={styles.heroHealth}>
                  <img src={healthIcon} />
                  {hero.stats.isHpAndMpTransformed
                    ? Math.round(Number(hero.stats.hp || 0) / 100)
                    : hero.stats.hp}
                  <span className={styles.tooltip}>Health</span>
                </div>
                <div className={styles.heroMana}>
                  <img src={manaIcon} />
                  {hero.stats.isHpAndMpTransformed
                    ? Math.round(Number(hero.stats.mp || 0) / 100)
                    : hero.stats.mp}
                  <span className={styles.tooltip}>Mana</span>
                </div>
                <div className={styles.heroCardFrame}>
                  <div className={`${styles.specials} ${styles.row}`}>
                    <div className={styles.icon}>
                      {hero.element == 'fire' && <img src={fireIcon} />}
                      {hero.element == 'water' && <img src={waterIcon} />}
                      {hero.element == 'earth' && <img src={earthIcon} />}
                      {hero.element == 'wind' && <img src={windIcon} />}
                      {hero.element == 'lightning' && (
                        <img src={lightningIcon} />
                      )}
                      {hero.element == 'ice' && <img src={iceIcon} />}
                      {hero.element == 'light' && <img src={lightIcon} />}
                      {hero.element == 'dark' && <img src={darkIcon} />}
                      <span className={styles.tooltip}>{hero.element}</span>
                    </div>
                    <div className={styles.icon}>
                      {hero.visualGenes.background == 'arctic' && (
                        <img src={arcticIcon} />
                      )}
                      {hero.visualGenes.background == 'city' && (
                        <img src={cityIcon} />
                      )}
                      {hero.visualGenes.background == 'desert' && (
                        <img src={desertIcon} />
                      )}
                      {hero.visualGenes.background == 'forest' && (
                        <img src={forestIcon} />
                      )}
                      {hero.visualGenes.background == 'island' && (
                        <img src={islandIcon} />
                      )}
                      {hero.visualGenes.background == 'mountains' && (
                        <img src={mountainIcon} />
                      )}
                      {hero.visualGenes.background == 'plains' && (
                        <img src={plainsIcon} />
                      )}
                      {hero.visualGenes.background == 'swamp' && (
                        <img src={swampIcon} />
                      )}
                      <span className={styles.tooltip}>{hero.background}</span>
                    </div>
                    <div className={styles.icon}>
                      <img
                        src={hero.gender == 'female' ? femaleIcon : maleIcon}
                      />
                      <span className={styles.tooltip}>{hero.gender}</span>
                    </div>
                  </div>

                  <div className={styles.heroName}>
                    <span>{hero.name}</span>
                  </div>

                  <div className={styles.heroPreview}>
                    <div className={styles.heroGlow} />
                    <Hero hero={hero} />
                  </div>

                  <div className={styles.heroInfo}>
                    <div className={styles.class}>
                      {hero.class}
                      <span className={styles.subClass}>{hero.subClass}</span>
                    </div>
                    <div className={styles.cardRarity}>
                      <div className={styles.icon}>
                        {hero.rarity == 'common' && <img src={commonIcon} />}
                        {hero.rarity == 'uncommon' && (
                          <img src={uncommonIcon} />
                        )}
                        {hero.rarity == 'rare' && <img src={rareIcon} />}
                        {hero.rarity == 'legendary' && (
                          <img src={legendaryIcon} />
                        )}
                        {hero.rarity == 'mythic' && <img src={mythicIcon} />}
                        <span className={styles.tooltip}>{hero.rarity}</span>
                      </div>
                    </div>
                    <div className={styles.level}>
                      Level {hero.level}
                      <span className={styles.subClass}>
                        Gen {hero.generation}
                      </span>
                    </div>
                  </div>

                  <HeroInfo hero={hero} />
                </div>
              </div>
              <div className={styles.heroCardBack}>
                <div className={styles.heroCardFrame}>
                  <div className={`${styles.specials} ${styles.row}`}>
                    <div className={styles.icon}>
                      {hero.element == 'fire' && <img src={fireIcon} />}
                      {hero.element == 'water' && <img src={waterIcon} />}
                      {hero.element == 'earth' && <img src={earthIcon} />}
                      {hero.element == 'wind' && <img src={windIcon} />}
                      {hero.element == 'lightning' && (
                        <img src={lightningIcon} />
                      )}
                      {hero.element == 'ice' && <img src={iceIcon} />}
                      {hero.element == 'light' && <img src={lightIcon} />}
                      {hero.element == 'dark' && <img src={darkIcon} />}
                      <span className={styles.tooltip}>{hero.element}</span>
                    </div>
                    <div className={styles.icon}>
                      {hero.visualGenes.background == 'arctic' && (
                        <img src={arcticIcon} />
                      )}
                      {hero.visualGenes.background == 'city' && (
                        <img src={cityIcon} />
                      )}
                      {hero.visualGenes.background == 'desert' && (
                        <img src={desertIcon} />
                      )}
                      {hero.visualGenes.background == 'forest' && (
                        <img src={forestIcon} />
                      )}
                      {hero.visualGenes.background == 'island' && (
                        <img src={islandIcon} />
                      )}
                      {hero.visualGenes.background == 'mountains' && (
                        <img src={mountainIcon} />
                      )}
                      {hero.visualGenes.background == 'plains' && (
                        <img src={plainsIcon} />
                      )}
                      {hero.visualGenes.background == 'swamp' && (
                        <img src={swampIcon} />
                      )}
                      <span className={styles.tooltip}>{hero.background}</span>
                    </div>
                    <div className={styles.icon}>
                      <img
                        src={hero.gender == 'female' ? femaleIcon : maleIcon}
                      />
                      <span className={styles.tooltip}>{hero.gender}</span>
                    </div>
                  </div>

                  <div className={styles.heroStats}>
                    <div className={styles.heroFrame}>
                      <HeroStatsSkills hero={hero} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardContainer>
        </div>
      )}
    </>
  )
}

export default React.memo(HeroCard)
