import _ from 'lodash'
import { HeroOptionsForm } from '../../modules/hero/heroFilterFormInterface'

export function isFormEmpty(form: HeroOptionsForm) {
  for (const option in form) {
    if (form[option] !== undefined) return false
  }
  return true
}

export function filterHeroes(heroOptionsForm: HeroOptionsForm, heroes) {
  let filteredHeroes = heroes
  let filters = []

  if (!isFormEmpty(heroOptionsForm)) {
    for (const option in heroOptionsForm) {
      if (heroOptionsForm[option])
        filters.push({
          name: option,
          value: _.camelCase(heroOptionsForm[option].label),
        })
    }

    filteredHeroes = filters.reduce(function (prev, curr) {
      return prev.filter((filter) => {
        return String(filter[curr.name]) === curr.value
      })
    }, heroes)
  }

  return filteredHeroes
}
